import * as React from 'react';
import { Link } from 'react-router-dom';

import { IProduct } from '../types/product';

import './styles/product-slide-item.scss';

export interface ProductSlideItemProps {
    product: IProduct;
}

export const ProductSlideItem: React.FC<ProductSlideItemProps> = ({
    product,
}) => {
    return (
        <>
            <div className="card-product">

                <div className="imgBox">
                <img src={product.imageUrl?.[0]||product.sizes?.[0]?.imageUrl?.[0] ||'default.png'} alt="mouse corsair" className="mouse" />
                </div>

                <div className="contentBox">
                    <h3>{product.name}</h3>
                    <h2 className="price"><small>{product.extra}</small></h2>
                    <Link to={`/product/${product.id}`} className="buy">View</Link>
                </div>

            </div>
        </>
    )
}